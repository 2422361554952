import _, { get, pick, zipObject } from 'lodash';
import { useSelector } from 'react-redux';
import { call, takeEvery, takeLeading } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import { AnyAction } from 'redux';

import { sanitize } from '@ivymobi/orbit-domain';

import {
  createPromiseAction, rejectPromiseAction, resolvePromiseAction
} from '../util/redux-saga-promise';
import { Tenant, ContextUser } from '../types';

export const useStats = ({user, tenant, type}: {user: ContextUser, tenant: Tenant, type: 'tenant'|'shares'}) => useSelector(
  (state) => get(state, [user.user.id, 'tenants', tenant.id, 'stats', type], {})
);

export const getExportAction = createPromiseAction(
  'stats/EXPORT',
  data => pick(data, ['dateRange']),
  data => pick(data, ['user', 'tenant', 'mode', 'select', 'fileType', 'fileName', 'lastURL']),
);
function* watchGetExportEvents(api) {
  yield takeEvery<AnyAction>(getExportAction, function*(action) {
    const {
      meta: { user, tenant, mode, select, fileType, fileName, lastURL },
      payload: { dateRange }
    } = action;
    if (fileType === 'details') {
      try { 
        const res = yield call(api.downloadFile, {
          url: `stats/${tenant.id}/${select}/${lastURL}?startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}`,
          token: user.token,
          fileName: fileName
        });
        yield call(resolvePromiseAction, action, res);
      } catch (error) {
        yield call(rejectPromiseAction, action, error);
      }
    } else {
      try { 
        // const res = yield call(api.getJSON, {
        //   url: 'stats/export',
        //   token: action.meta.user.token,
        // });
        const res = yield call(api.downloadFile, {
          url: `stats/${tenant.id}/${mode}/tableExport?startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}`,
          token: user.token,
          fileName: fileName
        });
        yield call(resolvePromiseAction, action, res);
      } catch (error) {
        yield call(rejectPromiseAction, action, error);
      }
    }
  });
}

export const getStatsAction = createPromiseAction(
  'stats/GET',
  (data: {type: string, id?: string, date}) => pick(data, ['type', 'id', 'date']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchGetStats(api) {
  yield takeEvery(getStatsAction, function* (action) {
    const {meta: {user: {token, user}, tenant}} = action;
    const {payload: {type, id, date}} = action;
    // const url = (type === 'tenant') ?
    //   `stats/${tenant.id}?startDate=${date[0].toISOString()}&endDate=${date[1].toISOString()}`
    //   : `stats/${tenant.id}/${type}/${id}`;
    // console.log('datedatedatedatedatedatedate', date);
    let url = '';
    console.log('type==',type)
    if ((type !== 'tenant') && (type !== 'all') ) {
      url = `stats/${tenant.id}/${type}/${id}`;
    } else {
      console.log('data===============',_.isEmpty(date),date)
      _.isEmpty(date) ? 
        url = `stats/${tenant.id}` 
        : url = `stats/${tenant.id}?startDate=${date[0].toISOString()}&endDate=${date[1].toISOString()}`;
    }
    try {
      let stats = yield call(api.getJSON, {
        url,
        token,
      });
      stats = yield sanitize.stats(stats);
   
      yield call(resolvePromiseAction, action,
        {type, id, stats}, {user, tenant}
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getselLeadsInfoByphoneAction = createPromiseAction(
  'stats/GET_LeadsInfoByphone',
  (data: {phone: string}) => pick(data, ['phone']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchSelLeadsInfoByphone(api) {
  yield takeLeading(getselLeadsInfoByphoneAction, function* (action) {
    const {meta: {user: {token, user}, tenant}} = action;
    const {payload: {phone}} = action;
    const url = `stats/selLeadsInfoByphone?phone=${phone}&tenantid=${tenant?.id}`;
    try {
      let stats = yield call(api.getJSON, {
        url,
        token,
      });
      stats = yield sanitize.stats(stats);
   
      yield call(resolvePromiseAction, action,
        stats, {user, tenant}
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getTenantsIsLeadsInfoByphoneAction = createPromiseAction(
  'stats/GET_IsLeadsInfoByphone',
  (data: {phone: string}) => pick(data, ['phone']),
  (data: {user: ContextUser, tenant: Tenant}) => pick(data, ['user', 'tenant'])
);

function* watchIsLeadsInfo(api) {
  yield takeLeading(getTenantsIsLeadsInfoByphoneAction, function* (action) {
    const {meta: {user: {token, user}, tenant}} = action;
    const {payload: {phone}} = action;
    const url = `stats/${tenant.id}/selTenantsIsLeadsInfoByphone?phone=${phone}`;
    try {
      let stats = yield call(api.getJSON, {
        url,
        token,
      });
      stats = yield sanitize.stats(stats);
   
      yield call(resolvePromiseAction, action,
        stats, {user, tenant}
      );
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


export const getShareEventsAction = createPromiseAction(
  'shares/GET_EVENTS',
  (data: {id: string}) => pick(data, ['id']),
  ({user, tenant}) => ({user, tenant})
);

function* watchGetShareEvents(api) {
  yield takeLeading(getShareEventsAction, function* (action) {
    try {
      const {fields, data} = yield call(api.getJSON, {
        url: `stats/${action.meta.tenant.id}/shares/${action.payload.id}/events`,
        token: action.meta.user.token,
      });

      const leads = data.map((values) => zipObject(fields, values));
      yield call(resolvePromiseAction, action, leads);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//数据统计内容table与数据库交互
export const getTenantDocumentStatsAction = createPromiseAction(
  'stats/TENANT_DOCUMENTS',
  data => pick(data, ['size', 'skip', 'sortModel', 'filterValue', 'page', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetTenantDocumentsStats(api) {
  yield takeLeading<AnyAction>(getTenantDocumentStatsAction, function*(action) {
    const sorts = action.payload.sortModel;
    // sorts.length > 0 ? [{"column": sorts.field, "sort": sorts.sort}] : []
    // filterValue.columnField operatorValue value
    const filters = action.payload.filterValue;
    // filters.hasOwnProperty('value') ? [{"column": filters.columnField, "method": filters.operatorValue, "value": filters.value}] : []
    const dataobj = {
      startDate: action.payload.date[0].toISOString(),
      endDate: action.payload.date[1].toISOString(),
      size: action.payload.size.toString(),
      offset: action.payload.page.toString(),
      // sorted?: [],
      // condition?: []
    };
    if (sorts!== undefined) {
      sorts.length > 0 ? dataobj.sorted = [{'column': sorts[0].field, 'sort': sorts[0].sort}] : '';
    }
    filters !== undefined && filters.hasOwnProperty('value') ? dataobj.condition = [{'column': filters.columnField, 'method': filters.operatorValue, 'value': filters.value}] : '';

    const {
      meta: { user, tenant}
    } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `stats/${tenant.id}/documents`,
        body: dataobj,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}


export const getProuctsStatsAction = createPromiseAction(
  'stats/TENANT_PRODUCTS_DOCUMENTS',
  data => pick(data, ['size', 'skip', 'sortModel', 'filterValue', 'page', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetProductsStats(api) {
  yield takeLeading<AnyAction>(getProuctsStatsAction, function*(action) {
    const sorts = action.payload.sortModel;
    // sorts.length > 0 ? [{"column": sorts.field, "sort": sorts.sort}] : []
    // filterValue.columnField operatorValue value
    const filters = action.payload.filterValue;
    // filters.hasOwnProperty('value') ? [{"column": filters.columnField, "method": filters.operatorValue, "value": filters.value}] : []
    const dataobj = {
      startDate: action.payload.date[0].toISOString(),
      endDate: action.payload.date[1].toISOString(),
      size: action.payload.size.toString(),
      offset: action.payload.page.toString(),
      // sorted?: [],
      // condition?: []
    };
    if (sorts!== undefined) {
      sorts.length > 0 ? dataobj.sorted = [{'column': sorts[0].field, 'sort': sorts[0].sort}] : '';
    }
    filters !== undefined && filters.hasOwnProperty('value') ? dataobj.condition = [{'column': filters.columnField, 'method': filters.operatorValue, 'value': filters.value}] : '';

    const {
      meta: { user, tenant}
    } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `stats/${tenant.id}/products`,
        body: dataobj,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getArticleStatsAction = createPromiseAction(
  'stats/TENANT_ARTICELS_DOCUMENTS',
  data => pick(data, ['size', 'skip', 'sortModel', 'filterValue', 'page', 'date']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetArticlesStats(api) {
  yield takeLeading<AnyAction>(getArticleStatsAction, function*(action) {
    const sorts = action.payload.sortModel;
    // sorts.length > 0 ? [{"column": sorts.field, "sort": sorts.sort}] : []
    // filterValue.columnField operatorValue value
    const filters = action.payload.filterValue;
    // filters.hasOwnProperty('value') ? [{"column": filters.columnField, "method": filters.operatorValue, "value": filters.value}] : []
    const dataobj = {
      startDate: action.payload.date[0].toISOString(),
      endDate: action.payload.date[1].toISOString(),
      size: action.payload.size.toString(),
      offset: action.payload.page.toString(),
      // sorted?: [],
      // condition?: []
    };
    if (sorts!== undefined) {
      sorts.length > 0 ? dataobj.sorted = [{'column': sorts[0].field, 'sort': sorts[0].sort}] : '';
    }
    filters !== undefined && filters.hasOwnProperty('value') ? dataobj.condition = [{'column': filters.columnField, 'method': filters.operatorValue, 'value': filters.value}] : '';

    const {
      meta: { user, tenant}
    } = action;
    try {
      const res = yield call(api.postJSON, {
        url: `stats/${tenant.id}/article`,
        body: dataobj,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}


export const getSelLeadsInfo = createPromiseAction(
  'stats/TENANT_DOCUMENTS_INFO',
  data => pick(data, ['tenant', 'documentId']),
);
function* watchSelLeadsInfo(api) {
  yield takeEvery<AnyAction>(getSelLeadsInfo, function*(action) {
    const {
      meta: { user, tenant, documentId }
    } = action;
    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/oneLeads/${documentId}/info`,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getResultInfo = createPromiseAction(
  'stats/STATES_RESULT_INFO',
  (data) => pick(data,  ['size', 'skip', 'page', 'date', 'url']),
  data => pick(data, ['user', 'tenant'])
);
function* watchResultInfo(api) {
  yield takeEvery<AnyAction>(getResultInfo, function*(action) {
    const {
      meta: { user, tenant }
    } = action;
    try {
      const res = yield call(api.getJSON, {
        url: action.payload.url+`?startDate=${action.payload.date[0].toISOString()}&endDate=${action.payload.date[1].toISOString()}&size=${action.payload.size}&offset=${action.payload.skip}`,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getTenantDocumentStatsDailyAction = createPromiseAction(
  'stats/TENANT_DOCUMENTS_DAILY',
  data => pick(data, ['dateRange']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetTenantDocumentsStatsDaily(api) {
  yield takeEvery<AnyAction>(getTenantDocumentStatsDailyAction, function*(action) {
    const {
      meta: { user, tenant },
      payload: { dateRange }
    } = action;

    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/documents/daily?from=${dateRange[0].toISOString()}&to=${dateRange[1].toISOString()}`,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getTenantUserDocumentStatsDailyAction = createPromiseAction(
  'stats/TENANT_USER_DOCUMENTS_DAILY',
  data => pick(data, ['dateRange']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetTenantUserDocumentsStatsDaily(api) {
  yield takeLeading<AnyAction>(getTenantUserDocumentStatsDailyAction, function*(action) {
    const {
      meta: { user, tenant },
      payload: { dateRange }
    } = action;
    
    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/user-documents/daily?from=${dateRange[0].toISOString()}&to=${dateRange[1].toISOString()}`,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const getTenantUserShareStatsDailyAction = createPromiseAction(
  'stats/TENANT_USER_DOCUMENTS_DAILY',
  data => pick(data, ['dateRange']),
  data => pick(data, ['user', 'tenant']),
);
function* watchGetTenantUserShareStatsDaily(api) {
  yield takeLeading<AnyAction>(getTenantUserShareStatsDailyAction, function*(action) {
    const {
      meta: { user, tenant },
      payload: { dateRange }
    } = action;
    
    try {
      const res = yield call(api.getJSON, {
        url: `stats/${tenant.id}/user-share/daily?from=${dateRange[0].toISOString()}&to=${dateRange[1].toISOString()}`,
        token: user.token
      });
      yield resolvePromiseAction(action, res);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const reducer = handleActions({
  [getStatsAction.resolved.toString()]: (state, {payload}) => {
    const {type, id, stats} = payload;
    if (type === 'tenant') {
      return {
        ...state,
        'tenant': stats,
      };
    }
    if (type === 'all') {
      return {
        ...state,
        'all': stats,
      };
    }

    const domainStats = {
      ...state[type],
      [id]: stats,
    };
    return {
      ...state,
      [type]: domainStats,
    };
  },
}, {
  tenant: {
    storage: {total: 0, delta: 0},
    netout: {total: 0, delta: 0},
    leads: {total: 0, delta: 0},
    view: {total: 0, delta: 0},
    like: {total: 0, delta: 0},
    share: {total: 0, delta: 0},
    download: {total: 0, delta: 0},
    activitiesView: {total: 0, delta: 0},
  },
  shares: {}
});

export const watchers = [
  watchGetStats, watchGetShareEvents,watchIsLeadsInfo,watchSelLeadsInfoByphone,
  watchGetTenantDocumentsStats,
  watchGetTenantDocumentsStatsDaily,
  watchGetProductsStats,
  watchGetArticlesStats,
  watchGetTenantUserDocumentsStatsDaily,
  watchGetTenantUserShareStatsDaily,
  watchGetExportEvents,
  watchResultInfo,
];
